<template>
  <Modal v-model="modal.show" :title="modal.data.name || '全景图'" width="1200" footer-hide class-name="panorama-view" fullscreen>
    <div class="h-100 map-wrap" ref="map" id="container"></div>
    <div class="map_tool">
      <ButtonGroup v-if="modal.data.qjtBtn && modal.data.qjtBtn.length">
        <Poptip word-wrap width="400" placement="bottom-start" :content="item.btnText" v-for="(item, index) in modal.data.qjtBtn" :key="index">
          <Button type="info" size="small">{{ item.btnName }}</Button>
        </Poptip>
      </ButtonGroup>
    </div>
  
  </Modal>
</template>

<script>
import { util } from '@/libs'
import * as Three from 'three'
// 导入轨道控制器
import {
  OrbitControls
} from 'three/examples/jsm/controls/OrbitControls'
export default {
  props: {
    modal: { type: Object },
  },
  data() {
    return {
      scene: null,
      camera: null,
      renderer: null,
      mesh: null,
      current: {},
      timer: null,
      flag: false
    }
  },
  methods: {
    init(){
      let container = document.getElementById('container');

      // 添加相机
      this.camera = new Three.PerspectiveCamera(70, container.clientWidth/container.clientHeight, 0.01, 1000);
      // 相机的位置
      this.camera.position.z = 0.1

      // 场景
      this.scene = new Three.Scene()


      // type: true,为立方体全景，false为球形
      if(this.modal.data.type) {
        // let urls = [
        //   '/static/images/panorama/北海港口r.jpg',
        //   '/static/images/panorama/北海港口l.jpg',
        //   '/static/images/panorama/北海港口u.jpg',
        //   '/static/images/panorama/北海港口d.jpg',
        //   '/static/images/panorama/北海港口f.jpg',
        //   '/static/images/panorama/北海港口b.jpg',
        // ]
        let urls = this.modal.data.urlList
        let cubeTexture = new Three.CubeTextureLoader().load(urls);
				this.scene.background = cubeTexture;

      } else {

        // 创建球
        let geometry = new Three.SphereBufferGeometry(5,32,32)
        let a = new Three.TextureLoader().load(this.modal.data.url)
        let material = new Three.MeshBasicMaterial({map: a });
        this.mesh = new Three.Mesh(geometry, material);
        this.mesh.geometry.scale(1, 1, -1);
        this.scene.add(this.mesh);

      }
        

      
      // 初始化渲染器
      this.renderer = new Three.WebGLRenderer({ antialias:true });
      // 渲染器的大小
      this.renderer.setSize(container.clientWidth,container.clientHeight);
      // 将渲染器添加到页面
      container.appendChild(this.renderer.domElement);



      // 创建轨道控制器 相机围绕模型看到的角度
      const OrbitControl = new OrbitControls(this.camera, this.renderer.domElement)
      // 设置轨道自然
      OrbitControl.enableDamping = true
      // 设置惯性
      OrbitControl.update()


    },
    animate() {
      // 浏览器自动渲染下一帧
      if(this.flag) {
        this.timer = window.requestAnimationFrame(this.animate);
      }

      
      // mesh.rotation.x += 0.001;
      // 沿Y轴顺时针旋转
      // mesh.rotation.y += 0.0005;
        
      this.camera.rotation.y -= 0.0005;
     
      // 渲染到页面
      this.renderer.render(this.scene, this.camera); 
    },
    stop() {
      window.cancelAnimationFrame(this.animate)
      this.timer = null
      this.flag = false
    },
  },
  mounted() {
    this.$watch("modal.show", (show) => {
      if(show) {
        this.flag = true
        this.init()
        this.animate()
        this.$forceUpdate()
      } else {
        this.stop()
        let container = document.getElementById('container');
        container.innerHTML = ""
      }
    })
  }
}
</script>

<style lang="less" scoped>
.panorama-view {
  .map-wrap {
    position: relative;
  }
  .map_tool {
    position: absolute;
    top: 20px;
    left: 20px;
  }
}
</style>
